function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {value, enumerable: true, configurable: true, writable: true});
  } else {
    obj[key] = value;
  }
  return obj;
}
import {AnalyticsHelpers, AnalyticsManager} from "../../../../__snowpack__/pkg/@internetarchive/analytics-manager.js";
export class AnalyticsHandler {
  constructor(options) {
    _defineProperty(this, "analyticsBackend", void 0);
    _defineProperty(this, "analyticsHelpers", void 0);
    if (!options.enableAnalytics)
      return;
    this.analyticsBackend = new AnalyticsManager();
    this.analyticsHelpers = new AnalyticsHelpers(this.analyticsBackend);
  }
  sendPing(values) {
    var _a;
    (_a = this.analyticsBackend) == null ? void 0 : _a.sendPing(values);
  }
  sendEvent(options) {
    var _a;
    (_a = this.analyticsBackend) == null ? void 0 : _a.sendEvent(options);
  }
  send_event(category, action, label, additionalEventParams) {
    this.sendEvent({
      category,
      action,
      label,
      eventConfiguration: additionalEventParams
    });
  }
  sendEventNoSampling(options) {
    var _a;
    (_a = this.analyticsBackend) == null ? void 0 : _a.sendEventNoSampling(options);
  }
  trackIaxParameter(location) {
    var _a;
    (_a = this.analyticsHelpers) == null ? void 0 : _a.trackIaxParameter(location);
  }
  trackPageView(options) {
    var _a;
    (_a = this.analyticsHelpers) == null ? void 0 : _a.trackPageView(options);
  }
}
