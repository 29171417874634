function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {value, enumerable: true, configurable: true, writable: true});
  } else {
    obj[key] = value;
  }
  return obj;
}
export class DonationBannerHandler {
  async pageNavigationOccurred(patronShouldSeeBanner = true) {
    if (window.location.pathname.startsWith("/donate") || !patronShouldSeeBanner) {
      await this.removeDonationBanner();
      return;
    }
    await this.addDonationBanner();
  }
  constructor(options) {
    _defineProperty(this, "ownerId", "donationBannerHandler");
    _defineProperty(this, "baseUrl", void 0);
    this.baseUrl = options.baseUrl;
  }
  async removeDonationBanner() {
    var _a;
    (_a = this.bannerContainer) == null ? void 0 : _a.remove();
  }
  async addDonationBanner() {
    if (this.bannerContainer)
      return;
    const containerDiv = document.createElement("div");
    containerDiv.dataset.owner = this.ownerId;
    const iframeContainer = document.createElement("div");
    iframeContainer.id = "donato";
    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.src = `https://${this.baseUrl}/includes/donate.js`;
    containerDiv.appendChild(iframeContainer);
    containerDiv.appendChild(scriptTag);
    document.body.prepend(containerDiv);
  }
  get bannerContainer() {
    return document.body.querySelector(`[data-owner=${this.ownerId}]`);
  }
}
