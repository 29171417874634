function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {value, enumerable: true, configurable: true, writable: true});
  } else {
    obj[key] = value;
  }
  return obj;
}
export class DonateBannerService {
  async getBannerConfig(options) {
    const searchParams = new URLSearchParams();
    if (options == null ? void 0 : options.ymd)
      searchParams.append("ymd", options.ymd);
    if (options == null ? void 0 : options.variant)
      searchParams.append("variant", options.variant);
    if (options == null ? void 0 : options.platform)
      searchParams.append("platform", options.platform);
    if (options == null ? void 0 : options.recacheDonationBanner)
      searchParams.append("recacheDonationBanner", options.recacheDonationBanner);
    let url = this.endpoint;
    const searchString = searchParams.toString();
    if (searchString.length > 0) {
      url = `${url}?${searchString}`;
    }
    const decoded = await this.fetchHandler.fetchIAApiResponse(url, {
      includeCredentials: true
    });
    return decoded.value;
  }
  constructor(options) {
    _defineProperty(this, "fetchHandler", void 0);
    _defineProperty(this, "endpoint", "/services/donations/banner.php");
    this.fetchHandler = options.fetchHandler;
  }
}
