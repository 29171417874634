var __assign = Object.assign;
import * as __SNOWPACK_ENV__ from "../__snowpack__/env.js";
function _decorate(decorators, factory, superClass, mixins) {
  var api = _getDecoratorsApi();
  if (mixins) {
    for (var i = 0; i < mixins.length; i++) {
      api = mixins[i](api);
    }
  }
  var r = factory(function initialize(O) {
    api.initializeInstanceElements(O, decorated.elements);
  }, superClass);
  var decorated = api.decorateClass(_coalesceClassElements(r.d.map(_createElementDescriptor)), decorators);
  api.initializeClassElements(r.F, decorated.elements);
  return api.runClassFinishers(r.F, decorated.finishers);
}
function _getDecoratorsApi() {
  _getDecoratorsApi = function() {
    return api;
  };
  var api = {elementsDefinitionOrder: [["method"], ["field"]], initializeInstanceElements: function(O, elements) {
    ["method", "field"].forEach(function(kind) {
      elements.forEach(function(element) {
        if (element.kind === kind && element.placement === "own") {
          this.defineClassElement(O, element);
        }
      }, this);
    }, this);
  }, initializeClassElements: function(F, elements) {
    var proto = F.prototype;
    ["method", "field"].forEach(function(kind) {
      elements.forEach(function(element) {
        var placement = element.placement;
        if (element.kind === kind && (placement === "static" || placement === "prototype")) {
          var receiver = placement === "static" ? F : proto;
          this.defineClassElement(receiver, element);
        }
      }, this);
    }, this);
  }, defineClassElement: function(receiver, element) {
    var descriptor = element.descriptor;
    if (element.kind === "field") {
      var initializer = element.initializer;
      descriptor = {enumerable: descriptor.enumerable, writable: descriptor.writable, configurable: descriptor.configurable, value: initializer === void 0 ? void 0 : initializer.call(receiver)};
    }
    Object.defineProperty(receiver, element.key, descriptor);
  }, decorateClass: function(elements, decorators) {
    var newElements = [];
    var finishers = [];
    var placements = {static: [], prototype: [], own: []};
    elements.forEach(function(element) {
      this.addElementPlacement(element, placements);
    }, this);
    elements.forEach(function(element) {
      if (!_hasDecorators(element))
        return newElements.push(element);
      var elementFinishersExtras = this.decorateElement(element, placements);
      newElements.push(elementFinishersExtras.element);
      newElements.push.apply(newElements, elementFinishersExtras.extras);
      finishers.push.apply(finishers, elementFinishersExtras.finishers);
    }, this);
    if (!decorators) {
      return {elements: newElements, finishers};
    }
    var result = this.decorateConstructor(newElements, decorators);
    finishers.push.apply(finishers, result.finishers);
    result.finishers = finishers;
    return result;
  }, addElementPlacement: function(element, placements, silent) {
    var keys = placements[element.placement];
    if (!silent && keys.indexOf(element.key) !== -1) {
      throw new TypeError("Duplicated element (" + element.key + ")");
    }
    keys.push(element.key);
  }, decorateElement: function(element, placements) {
    var extras = [];
    var finishers = [];
    for (var decorators = element.decorators, i = decorators.length - 1; i >= 0; i--) {
      var keys = placements[element.placement];
      keys.splice(keys.indexOf(element.key), 1);
      var elementObject = this.fromElementDescriptor(element);
      var elementFinisherExtras = this.toElementFinisherExtras((0, decorators[i])(elementObject) || elementObject);
      element = elementFinisherExtras.element;
      this.addElementPlacement(element, placements);
      if (elementFinisherExtras.finisher) {
        finishers.push(elementFinisherExtras.finisher);
      }
      var newExtras = elementFinisherExtras.extras;
      if (newExtras) {
        for (var j = 0; j < newExtras.length; j++) {
          this.addElementPlacement(newExtras[j], placements);
        }
        extras.push.apply(extras, newExtras);
      }
    }
    return {element, finishers, extras};
  }, decorateConstructor: function(elements, decorators) {
    var finishers = [];
    for (var i = decorators.length - 1; i >= 0; i--) {
      var obj = this.fromClassDescriptor(elements);
      var elementsAndFinisher = this.toClassDescriptor((0, decorators[i])(obj) || obj);
      if (elementsAndFinisher.finisher !== void 0) {
        finishers.push(elementsAndFinisher.finisher);
      }
      if (elementsAndFinisher.elements !== void 0) {
        elements = elementsAndFinisher.elements;
        for (var j = 0; j < elements.length - 1; j++) {
          for (var k = j + 1; k < elements.length; k++) {
            if (elements[j].key === elements[k].key && elements[j].placement === elements[k].placement) {
              throw new TypeError("Duplicated element (" + elements[j].key + ")");
            }
          }
        }
      }
    }
    return {elements, finishers};
  }, fromElementDescriptor: function(element) {
    var obj = {kind: element.kind, key: element.key, placement: element.placement, descriptor: element.descriptor};
    var desc = {value: "Descriptor", configurable: true};
    Object.defineProperty(obj, Symbol.toStringTag, desc);
    if (element.kind === "field")
      obj.initializer = element.initializer;
    return obj;
  }, toElementDescriptors: function(elementObjects) {
    if (elementObjects === void 0)
      return;
    return _toArray(elementObjects).map(function(elementObject) {
      var element = this.toElementDescriptor(elementObject);
      this.disallowProperty(elementObject, "finisher", "An element descriptor");
      this.disallowProperty(elementObject, "extras", "An element descriptor");
      return element;
    }, this);
  }, toElementDescriptor: function(elementObject) {
    var kind = String(elementObject.kind);
    if (kind !== "method" && kind !== "field") {
      throw new TypeError(`An element descriptor's .kind property must be either "method" or "field", but a decorator created an element descriptor with .kind "` + kind + '"');
    }
    var key = _toPropertyKey(elementObject.key);
    var placement = String(elementObject.placement);
    if (placement !== "static" && placement !== "prototype" && placement !== "own") {
      throw new TypeError(`An element descriptor's .placement property must be one of "static", "prototype" or "own", but a decorator created an element descriptor with .placement "` + placement + '"');
    }
    var descriptor = elementObject.descriptor;
    this.disallowProperty(elementObject, "elements", "An element descriptor");
    var element = {kind, key, placement, descriptor: Object.assign({}, descriptor)};
    if (kind !== "field") {
      this.disallowProperty(elementObject, "initializer", "A method descriptor");
    } else {
      this.disallowProperty(descriptor, "get", "The property descriptor of a field descriptor");
      this.disallowProperty(descriptor, "set", "The property descriptor of a field descriptor");
      this.disallowProperty(descriptor, "value", "The property descriptor of a field descriptor");
      element.initializer = elementObject.initializer;
    }
    return element;
  }, toElementFinisherExtras: function(elementObject) {
    var element = this.toElementDescriptor(elementObject);
    var finisher = _optionalCallableProperty(elementObject, "finisher");
    var extras = this.toElementDescriptors(elementObject.extras);
    return {element, finisher, extras};
  }, fromClassDescriptor: function(elements) {
    var obj = {kind: "class", elements: elements.map(this.fromElementDescriptor, this)};
    var desc = {value: "Descriptor", configurable: true};
    Object.defineProperty(obj, Symbol.toStringTag, desc);
    return obj;
  }, toClassDescriptor: function(obj) {
    var kind = String(obj.kind);
    if (kind !== "class") {
      throw new TypeError(`A class descriptor's .kind property must be "class", but a decorator created a class descriptor with .kind "` + kind + '"');
    }
    this.disallowProperty(obj, "key", "A class descriptor");
    this.disallowProperty(obj, "placement", "A class descriptor");
    this.disallowProperty(obj, "descriptor", "A class descriptor");
    this.disallowProperty(obj, "initializer", "A class descriptor");
    this.disallowProperty(obj, "extras", "A class descriptor");
    var finisher = _optionalCallableProperty(obj, "finisher");
    var elements = this.toElementDescriptors(obj.elements);
    return {elements, finisher};
  }, runClassFinishers: function(constructor, finishers) {
    for (var i = 0; i < finishers.length; i++) {
      var newConstructor = (0, finishers[i])(constructor);
      if (newConstructor !== void 0) {
        if (typeof newConstructor !== "function") {
          throw new TypeError("Finishers must return a constructor.");
        }
        constructor = newConstructor;
      }
    }
    return constructor;
  }, disallowProperty: function(obj, name, objectType) {
    if (obj[name] !== void 0) {
      throw new TypeError(objectType + " can't have a ." + name + " property.");
    }
  }};
  return api;
}
function _createElementDescriptor(def) {
  var key = _toPropertyKey(def.key);
  var descriptor;
  if (def.kind === "method") {
    descriptor = {value: def.value, writable: true, configurable: true, enumerable: false};
  } else if (def.kind === "get") {
    descriptor = {get: def.value, configurable: true, enumerable: false};
  } else if (def.kind === "set") {
    descriptor = {set: def.value, configurable: true, enumerable: false};
  } else if (def.kind === "field") {
    descriptor = {configurable: true, writable: true, enumerable: true};
  }
  var element = {kind: def.kind === "field" ? "field" : "method", key, placement: def.static ? "static" : def.kind === "field" ? "own" : "prototype", descriptor};
  if (def.decorators)
    element.decorators = def.decorators;
  if (def.kind === "field")
    element.initializer = def.value;
  return element;
}
function _coalesceGetterSetter(element, other) {
  if (element.descriptor.get !== void 0) {
    other.descriptor.get = element.descriptor.get;
  } else {
    other.descriptor.set = element.descriptor.set;
  }
}
function _coalesceClassElements(elements) {
  var newElements = [];
  var isSameElement = function(other2) {
    return other2.kind === "method" && other2.key === element.key && other2.placement === element.placement;
  };
  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    var other;
    if (element.kind === "method" && (other = newElements.find(isSameElement))) {
      if (_isDataDescriptor(element.descriptor) || _isDataDescriptor(other.descriptor)) {
        if (_hasDecorators(element) || _hasDecorators(other)) {
          throw new ReferenceError("Duplicated methods (" + element.key + ") can't be decorated.");
        }
        other.descriptor = element.descriptor;
      } else {
        if (_hasDecorators(element)) {
          if (_hasDecorators(other)) {
            throw new ReferenceError("Decorators can't be placed on different accessors with for the same property (" + element.key + ").");
          }
          other.decorators = element.decorators;
        }
        _coalesceGetterSetter(element, other);
      }
    } else {
      newElements.push(element);
    }
  }
  return newElements;
}
function _hasDecorators(element) {
  return element.decorators && element.decorators.length;
}
function _isDataDescriptor(desc) {
  return desc !== void 0 && !(desc.value === void 0 && desc.writable === void 0);
}
function _optionalCallableProperty(obj, name) {
  var value = obj[name];
  if (value !== void 0 && typeof value !== "function") {
    throw new TypeError("Expected '" + name + "' to be a function");
  }
  return value;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function _toArray(arr) {
  return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter))
    return Array.from(iter);
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
import {LitElement, html, css, nothing} from "../__snowpack__/pkg/lit.js";
import {property, query, state, customElement} from "../__snowpack__/pkg/lit/decorators.js";
import {msg} from "../__snowpack__/pkg/@lit/localize.js";
import "../__snowpack__/pkg/@internetarchive/ia-topnav.js";
import "../__snowpack__/pkg/@internetarchive/router-slot.js";
import "./widgets/activity-indicators/horizontal-activity-indicator.js";
import {AppFooterMode} from "./app-footer-mode.js";
import {lazyLoadTemplate} from "./core/lazy-load-template.js";
import {PromisedSingleton} from "../__snowpack__/pkg/@internetarchive/promised-singleton.js";
import {DonationBannerHandler} from "./core/donation-banner/donation-banner-handler.js";
import {DonateBannerService} from "./core/donation-banner/donation-banner-service.js";
import {RouteBuilder} from "./core/route-builder/route-builder.js";
import {formatMediaCount} from "./utils/format-media-count.js";
import {importRetry} from "./utils/import-retry.js";
export let AppRoot = _decorate([customElement("app-root")], function(_initialize, _LitElement) {
  class AppRoot2 extends _LitElement {
    constructor(...args) {
      super(...args);
      _initialize(this);
    }
  }
  return {
    F: AppRoot2,
    d: [{
      kind: "field",
      decorators: [property({
        type: Object
      })],
      key: "appServices",
      value() {
        return new PromisedSingleton({
          generator: async () => {
            const module = await importRetry(() => import(
              /* webpackChunkName: 'appServices' */
              "./core/app-services/app-services.js"
            ));
            return module.AppServices.generateAppServices({
              appRoot: this,
              appConfig: this.appConfig,
              modalManager: new PromisedSingleton({
                generator: async () => {
                  return this.modalManager;
                }
              })
            });
          }
        });
      }
    }, {
      kind: "field",
      decorators: [property({
        type: Object
      })],
      key: "appConfig",
      value() {
        return new PromisedSingleton({
          generator: async () => {
            const metaEnv = __SNOWPACK_ENV__;
            const urlParams = new URLSearchParams(window.location.search);
            const prerenderParam = urlParams.get("prerender");
            const isPrerendering = prerenderParam === "1";
            const isTesting = metaEnv.SNOWPACK_PUBLIC_TESTING === "true";
            const suppressFacets = metaEnv.SNOWPACK_PUBLIC_SUPPRESS_FACETS === "true";
            const tracesSampleRate = parseFloat(metaEnv.SNOWPACK_PUBLIC_SENTRY_TRACES_SAMPLE_RATE);
            const sentryErrorSampleRate = parseFloat(metaEnv.SNOWPACK_PUBLIC_SENTRY_ERROR_SAMPLE_RATE);
            return {
              environment: metaEnv.SNOWPACK_PUBLIC_ENVIRONMENT,
              documentReferrer: document.referrer,
              basePath: metaEnv.SNOWPACK_PUBLIC_BASE_PATH,
              version: metaEnv.SNOWPACK_PUBLIC_VERSION,
              archiveNavUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_NAV_URL,
              archiveApiUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_API_URL,
              waybackUrl: metaEnv.SNOWPACK_PUBLIC_WAYBACK_URL,
              waybackSearchUrl: metaEnv.SNOWPACK_PUBLIC_WAYBACK_SEARCH_URL,
              tvSearchUrl: metaEnv.SNOWPACK_PUBLIC_TV_SEARCH_URL,
              radioSearchUrl: metaEnv.SNOWPACK_PUBLIC_RADIO_SEARCH_URL,
              archiveItCollectionSearchUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_IT_COLLECTION_URL,
              archiveItPartnerSearchUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_IT_PARTNER_URL,
              userListsUrl: metaEnv.SNOWPACK_PUBLIC_USER_LISTS_API_URL,
              itemManagementUrl: metaEnv.SNOWPACK_PUBLIC_ITEM_MANAGEMENT_URL,
              webArchivesServiceUrl: metaEnv.SNOWPACK_PUBLIC_WEB_ARCHIVES_SERVICE_URL,
              sentry: {
                dsn: metaEnv.SNOWPACK_PUBLIC_SENTRY_DSN,
                tracesSampleRate,
                sampleRate: sentryErrorSampleRate
              },
              zendeskHelpWidgetKey: metaEnv.SNOWPACK_PUBLIC_ZENDESK_HELP_WIDGET_KEY,
              isPrerendering,
              isTesting,
              suppressFacets,
              recaptchaSiteKey: metaEnv.SNOWPACK_PUBLIC_RECAPTCHA_SITE_KEY,
              donationForm: {
                braintreeAuthToken: metaEnv.SNOWPACK_PUBLIC_BRAINTREE_AUTH_TOKEN,
                venmoProfileId: metaEnv.SNOWPACK_PUBLIC_VENMO_PROFILE_ID,
                googlepayMerchantId: metaEnv.SNOWPACK_PUBLIC_GOOGLEPAY_MERCHANT_ID
              },
              featureFeedback: {
                serviceUrl: metaEnv.SNOWPACK_PUBLIC_FEATURE_FEEDBACK_SERVICE_URL
              },
              baseImageUrl: metaEnv.SNOWPACK_PUBLIC_ARCHIVE_IMAGE_URL,
              currentBaseUrl: window.location.origin
            };
          }
        });
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "footerPosition",
      value() {
        return AppFooterMode.Off;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "hasLoadedStrings",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "showDebugBorders",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "showDevMenu",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "activityIndicatorVisible",
      value() {
        return true;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "sharedResizeObserver",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "siteHasLimitedFunctionality",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "waybackPagesArchived",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "screenName",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "username",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "userHasItemsPriv",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "userHasFlagsPriv",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "zendeskWidgetVisible",
      value() {
        return false;
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "zendeskWidgetKey",
      value() {
        return "";
      }
    }, {
      kind: "field",
      decorators: [state()],
      key: "donationBannerVisible",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "psaBannerService",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "userService",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "localCache",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "keyValueStoreManager",
      value: void 0
    }, {
      kind: "field",
      decorators: [state()],
      key: "itemIdentifier",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("router-slot")],
      key: "routerSlot",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("ia-topnav")],
      key: "topNav",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("modal-manager")],
      key: "modalManager",
      value: void 0
    }, {
      kind: "field",
      decorators: [query("#maincontent")],
      key: "mainContent",
      value: void 0
    }, {
      kind: "field",
      key: "donationBannerHandler",
      value: void 0
    }, {
      kind: "field",
      key: "donationBannerService",
      value: void 0
    }, {
      kind: "method",
      key: "firstUpdated",
      value: function firstUpdated() {
        this.startup();
      }
    }, {
      kind: "method",
      key: "updated",
      value: function updated(changedProperties) {
        var _a;
        if (changedProperties.has("appServices") && this.appServices) {
          this.setupAppServicesDependents();
        }
        if (changedProperties.has("hasLoadedStrings") && this.hasLoadedStrings) {
          this.setupView();
        }
        if (changedProperties.has("donationBannerVisible") && this.donationBannerVisible) {
          (_a = this.donationBannerHandler) == null ? void 0 : _a.pageNavigationOccurred(!!this.donationBannerVisible);
        }
      }
    }, {
      kind: "method",
      key: "startup",
      value: async function startup() {
        this.checkForLimitedFunctionality();
        await Promise.all([this.setupDonationBannerService(), this.setupDonationBannerHandler(), this.setupPsaBanner(), this.setupKeyValueStoreManager(), this.setupZendeskWidget(), this.startupSentry()]);
      }
    }, {
      kind: "method",
      key: "setupIntersectionObserverPolyfill",
      value: async function setupIntersectionObserverPolyfill() {
        if ("IntersectionObserver" in window)
          return;
        const module = await importRetry(() => import(
          /* webpackChunkName: 'intersectionObserverPolyfill' */
          "../__snowpack__/pkg/intersection-observer-polyfill.js"
        ));
        window.IntersectionObserver = module.default;
        await this.trackEvent({
          event: {
            category: "offshootPolyfills",
            action: "loadIntersectionObserver",
            label: navigator.userAgent
          },
          sampling: true
        });
      }
    }, {
      kind: "method",
      key: "setupPsaBanner",
      value: async function setupPsaBanner() {
        const services = await this.appServices.get();
        await Promise.all([this.psaBannerService = await services.psaBannerService.get(), this.userService = await services.userService.get(), this.localCache = await services.localCache.get()]);
      }
    }, {
      kind: "method",
      key: "setupKeyValueStoreManager",
      value: async function setupKeyValueStoreManager() {
        const services = await this.appServices.get();
        this.keyValueStoreManager = await services.keyValueStoreManager.get();
      }
    }, {
      kind: "method",
      key: "setupZendeskWidget",
      value: async function setupZendeskWidget() {
        const appServices = await this.appServices.get();
        const appConfig = await appServices.appConfig.get();
        const configService = await appServices.configService.get();
        const value = await configService.getConfig("feature.zendesk_enabled");
        this.zendeskWidgetKey = appConfig.zendeskHelpWidgetKey;
        this.zendeskWidgetVisible = value !== "";
      }
    }, {
      kind: "method",
      key: "setupAppServicesDependents",
      value: async function setupAppServicesDependents() {
        await Promise.all([this.setupDevMenu(), this.setupLocalizationManager(), this.startupThemeManager(), this.trackIaxParameter(), this.setupPageViewAnalytics(), this.setupPageMetadataService(), this.setupLightDOMTunnelReset(), this.setupResizeObserver()]);
      }
    }, {
      kind: "method",
      key: "startupSentry",
      value: async function startupSentry() {
        const appConfig = await this.appConfig.get();
        if (appConfig.isTesting || appConfig.isPrerendering)
          return;
        const {
          SentryConfigurer
        } = await importRetry(() => import(
          /* webpackChunkName: 'sentry' */
          "./core/sentry.js"
        ));
        const sentryConfigurer = new SentryConfigurer(appConfig);
        sentryConfigurer.configure();
      }
    }, {
      kind: "method",
      key: "checkForLimitedFunctionality",
      value: async function checkForLimitedFunctionality() {
        const urlParams = new URLSearchParams(window.location.search);
        const limitedFunctionalityParam = urlParams.get("limited");
        this.siteHasLimitedFunctionality = limitedFunctionalityParam === "1";
      }
    }, {
      kind: "field",
      key: "iaxParameterTracked",
      value() {
        return false;
      }
    }, {
      kind: "method",
      key: "trackIaxParameter",
      value: async function trackIaxParameter() {
        if (this.iaxParameterTracked)
          return;
        const appServices = await this.appServices.get();
        const analyticsHelper = await appServices.analyticsHandler.get();
        analyticsHelper.trackIaxParameter(window.location.href);
        this.iaxParameterTracked = true;
      }
    }, {
      kind: "method",
      key: "setupDevMenu",
      value: async function setupDevMenu() {
        const appServices = await this.appServices.get();
        const appConfig = await appServices.appConfig.get();
        const user = await this.getLoggedInUser();
        const isDevEnvironment = appConfig.environment === "dev";
        const userHasPrivs = user && user.privs.includes("offshoot-dev") || false;
        this.showDevMenu = isDevEnvironment || userHasPrivs;
      }
    }, {
      kind: "method",
      key: "setupPageViewAnalytics",
      value: async function setupPageViewAnalytics() {
        window.addEventListener("navigationend", async () => {
          const appServices = await this.appServices.get();
          const analyticsHelper = await appServices.analyticsHandler.get();
          analyticsHelper.trackPageView();
        });
      }
    }, {
      kind: "method",
      key: "setupPageMetadataService",
      value: async function setupPageMetadataService() {
        window.addEventListener("navigationstart", async () => {
          const appServices = await this.appServices.get();
          const pageMetadataService = await appServices.pageMetadataService.get();
          pageMetadataService.clearMetaTags();
          pageMetadataService.clearLinkTags();
        });
      }
    }, {
      kind: "method",
      key: "setupLightDOMTunnelReset",
      value: async function setupLightDOMTunnelReset() {
        window.addEventListener("navigationstart", async () => {
          const appServices = await this.appServices.get();
          const lightDOMTunnel = await appServices.lightDOMTunnel.get();
          lightDOMTunnel.removeAll();
        });
      }
    }, {
      kind: "method",
      key: "setupResizeObserver",
      value: async function setupResizeObserver() {
        const appServices = await this.appServices.get();
        const resizeObserver = await appServices.resizeObserver.get();
        this.sharedResizeObserver = resizeObserver;
      }
    }, {
      kind: "method",
      key: "setupPageNavigationActivityIndicator",
      value: async function setupPageNavigationActivityIndicator() {
        window.addEventListener("navigationstart", async () => {
          this.showActivityIndicator(true);
        });
        window.addEventListener("navigationend", async () => {
          this.showActivityIndicator(false);
        });
      }
    }, {
      kind: "method",
      key: "setupDonationBannerService",
      value: async function setupDonationBannerService() {
        var _a, _b, _c, _d;
        const appServices = await this.appServices.get();
        const fetchHandler = await appServices.fetchHandler.get();
        this.donationBannerService = new DonateBannerService({
          fetchHandler
        });
        const urlParams = new URLSearchParams(window.location.search);
        const ymdParam = (_a = urlParams.get("ymd")) != null ? _a : void 0;
        const variantParam = (_b = urlParams.get("variant")) != null ? _b : void 0;
        const platformParam = (_c = urlParams.get("platform")) != null ? _c : void 0;
        const recacheParam = (_d = urlParams.get("recacheDonationBanner")) != null ? _d : void 0;
        const bannerConfig = await this.donationBannerService.getBannerConfig({
          ymd: ymdParam,
          variant: variantParam,
          platform: platformParam,
          recacheDonationBanner: recacheParam
        });
        if (!bannerConfig)
          return;
        this.donationBannerVisible = bannerConfig.shouldDisplayBanner;
      }
    }, {
      kind: "method",
      key: "setupDonationBannerHandler",
      value: async function setupDonationBannerHandler() {
        const appConfig = await this.appConfig.get();
        this.donationBannerHandler = new DonationBannerHandler({
          baseUrl: appConfig.archiveApiUrl
        });
        window.addEventListener("navigationend", async () => {
          var _a;
          await ((_a = this.donationBannerHandler) == null ? void 0 : _a.pageNavigationOccurred(!!this.donationBannerVisible));
        });
      }
    }, {
      kind: "method",
      key: "setupView",
      value: async function setupView() {
        await this.setupIntersectionObserverPolyfill();
        await Promise.all([this.setupPageNavigationActivityIndicator(), this.setupRouter(), this.setupTopnav()]);
      }
    }, {
      kind: "method",
      key: "setFooterPosition",
      value: async function setFooterPosition(footerPosition) {
        this.footerPosition = footerPosition;
      }
    }, {
      kind: "method",
      key: "showTopNavSearch",
      value: function showTopNavSearch(showSearch) {
        this.topNav.hideSearch = !showSearch;
      }
    }, {
      kind: "method",
      key: "showTopNav2ndLogo",
      value: function showTopNav2ndLogo(allow2ndLogo) {
        const slotMode = allow2ndLogo ? "allow" : "";
        this.topNav.secondIdentitySlotMode = slotMode;
      }
    }, {
      kind: "method",
      key: "showZendeskWidget",
      value: function showZendeskWidget(show) {
        this.zendeskWidgetVisible = show;
      }
    }, {
      kind: "method",
      key: "setItemIdentifier",
      value: function setItemIdentifier(identifier) {
        this.itemIdentifier = identifier;
      }
    }, {
      kind: "method",
      key: "addAllowedCustomElementsToPage",
      value: function addAllowedCustomElementsToPage() {
        this.convertDayInHistoryLinks();
        this.convertDisabilityEligibilityForms();
      }
    }, {
      kind: "method",
      key: "convertDayInHistoryLinks",
      value: function convertDayInHistoryLinks() {
        var _a, _b;
        const collectionPage = (_a = this.routerSlot) == null ? void 0 : _a.querySelector("collection-page");
        const dayInHistoryLinks = (_b = collectionPage == null ? void 0 : collectionPage.shadowRoot) == null ? void 0 : _b.querySelectorAll('a[onclick*="tapeoftheday("]');
        dayInHistoryLinks == null ? void 0 : dayInHistoryLinks.forEach((link) => {
          const text = link.textContent;
          const replacementElmt = document.createElement("day-in-history");
          replacementElmt.textContent = text;
          link.replaceWith(replacementElmt);
        });
      }
    }, {
      kind: "method",
      key: "convertDisabilityEligibilityForms",
      value: function convertDisabilityEligibilityForms() {
        var _a, _b, _c;
        const collectionPage = (_a = this.routerSlot) == null ? void 0 : _a.querySelector("collection-page");
        const aboutSection = (_b = collectionPage == null ? void 0 : collectionPage.shadowRoot) == null ? void 0 : _b.querySelector("collection-about");
        const formSections = (_c = aboutSection == null ? void 0 : aboutSection.shadowRoot) == null ? void 0 : _c.querySelectorAll(".disability-eligibility-provider-form");
        formSections == null ? void 0 : formSections.forEach((section) => {
          var _a2, _b2, _c2, _d, _e, _f;
          const parent = section.parentElement;
          const replacementElmt = document.createElement("disability-eligibility-form");
          const form = section.querySelector("form");
          replacementElmt.setAttribute("type", (_a2 = form == null ? void 0 : form.dataset.disabilityEligibilityType) != null ? _a2 : "");
          replacementElmt.toggleAttribute("ispassword", !!section.querySelector('input[type="password"]'));
          replacementElmt.toggleAttribute("open", (_b2 = parent == null ? void 0 : parent.open) != null ? _b2 : false);
          const title = parent == null ? void 0 : parent.querySelector("h2");
          title == null ? void 0 : title.setAttribute("slot", "title");
          replacementElmt.append(title != null ? title : "");
          const label = document.createElement("span");
          label.textContent = (_d = (_c2 = section.querySelector("label")) == null ? void 0 : _c2.textContent) != null ? _d : "";
          label.setAttribute("slot", "input-label");
          replacementElmt.append(label);
          (_e = section.querySelectorAll("p")) == null ? void 0 : _e.forEach((para) => {
            replacementElmt.append(para);
          });
          (_f = section.parentElement) == null ? void 0 : _f.replaceWith(replacementElmt);
        });
      }
    }, {
      kind: "method",
      key: "getWindowScrollbarWidth",
      value: function getWindowScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
      }
    }, {
      kind: "field",
      key: "activityIndicatorRequests",
      value() {
        return 0;
      }
    }, {
      kind: "method",
      key: "showActivityIndicator",
      value: function showActivityIndicator(showIndicator) {
        this.activityIndicatorRequests += showIndicator ? 1 : -1;
        this.activityIndicatorRequests = Math.max(0, this.activityIndicatorRequests);
        const shouldHideIndicator = !showIndicator && this.activityIndicatorRequests === 0;
        this.activityIndicatorVisible = shouldHideIndicator ? false : true;
      }
    }, {
      kind: "method",
      key: "isListsCalloutEnabled",
      value: async function isListsCalloutEnabled() {
        var _a, _b;
        try {
          const appServices = await this.appServices.get();
          const [keyValueStoreManager, abtestManager] = await Promise.all([appServices.keyValueStoreManager.get(), appServices.abtestManager.get()]);
          const userListsStore = keyValueStoreManager == null ? void 0 : keyValueStoreManager.getKeyValueStore({
            namespace: "UserLists",
            storageType: "localStorage"
          });
          const [calloutsVariant, alreadyDismissed] = await Promise.all([abtestManager.variantFor("UserListsLaunchCallouts"), userListsStore == null ? void 0 : userListsStore.get("tabCalloutDismissed")]);
          return calloutsVariant === "On" && !alreadyDismissed;
        } catch (err) {
          (_b = (_a = window.Sentry) == null ? void 0 : _a.captureMessage) == null ? void 0 : _b.call(_a, "Unable to load lists callout state: " + err, "error");
          return false;
        }
      }
    }, {
      kind: "method",
      key: "setupTopnav",
      value: async function setupTopnav() {
        const appConfig = await this.appConfig.get();
        this.topNav.baseHost = appConfig.archiveNavUrl;
        this.waybackPagesArchived = await this.getWaybackPagesArchived();
        const user = await this.getLoggedInUser();
        if (!user)
          return;
        this.screenName = user.screenname;
        this.username = user.itemname.replace(/^@/, "");
        const hasSlashPriv = user.privs.includes("/");
        this.userHasItemsPriv = hasSlashPriv || user.privs.includes("/items");
        this.userHasFlagsPriv = hasSlashPriv || user.privs.includes("/flags");
        await this.updateTopNavCallouts();
      }
    }, {
      kind: "method",
      key: "updateTopNavCallouts",
      value: async function updateTopNavCallouts() {
        const showListsCallout = await this.isListsCalloutEnabled();
        if (this.topNav.config) {
          this.topNav.config = __assign(__assign({}, this.topNav.config), {
            callouts: showListsCallout ? {
              "My lists": "NEW"
            } : {}
          });
        }
      }
    }, {
      kind: "method",
      key: "getWaybackPagesArchived",
      value: async function getWaybackPagesArchived() {
        var _a;
        const appServices = await this.appServices.get();
        const mediaCountService = await appServices.mediaCountService.get();
        const mediaCounts = await mediaCountService.fetchMediaCounts();
        return formatMediaCount((_a = mediaCounts == null ? void 0 : mediaCounts.web) != null ? _a : 0);
      }
    }, {
      kind: "method",
      key: "getLoggedInUser",
      value: async function getLoggedInUser() {
        const appServices = await this.appServices.get();
        const userService = await appServices.userService.get();
        const result = await userService.getLoggedInUser();
        return result.success;
      }
    }, {
      kind: "method",
      key: "startupThemeManager",
      value: async function startupThemeManager() {
        const appServices = await this.appServices.get();
        const themeManager = await appServices.themeManager.get();
        await themeManager.startup();
      }
    }, {
      kind: "method",
      key: "setupRouter",
      value: async function setupRouter() {
        if (!this.routerSlot)
          return;
        const routeBuilder = new RouteBuilder(this.appServices);
        this.routerSlot.handleAnchorLinks = false;
        this.routerSlot.add(routeBuilder.appRoutes);
      }
    }, {
      kind: "method",
      key: "setupLocalizationManager",
      value: async function setupLocalizationManager() {
        const appServices = await this.appServices.get();
        const localizationManager = await appServices.localizationManager.get();
        this.showActivityIndicator(true);
        await localizationManager.setup();
        this.hasLoadedStrings = true;
        this.showActivityIndicator(false);
      }
    }, {
      kind: "method",
      key: "render",
      value: function render() {
        return html`
      <a
        href="#maincontent"
        id="skip-to-main-content-link"
        @click=${this.skipToMainContent}
        >${msg("Skip to main content")}</a
      >

      <header>
        ${this.psaBannerTemplate}
        ${this.siteHasLimitedFunctionality ? this.limitedSiteFunctionalityBannerTemplate : nothing}
        <ia-topnav
          localLinks="true"
          waybackPagesArchived=${this.waybackPagesArchived}
          @trackClick=${this.trackTopNavEvent}
          @trackSubmit=${this.trackTopNavEvent}
          screenName=${this.screenName}
          username=${this.username}
          itemIdentifier=${this.itemIdentifier}
          ?admin=${this.userHasItemsPriv}
          ?canManageFlags=${this.userHasFlagsPriv}
        >
        </ia-topnav>
        ${this.showDevMenu ? this.devMenuTemplate : nothing}
        ${this.activityIndicatorVisible ? this.activityIndicatorTemplate : nothing}
      </header>

      ${this.hasLoadedStrings ? this.mainTemplate : nothing}
      ${this.modalManagerTemplate}
      ${this.zendeskWidgetVisible ? this.zendeskHelpWidgetTemplate : nothing}
    `;
      }
    }, {
      kind: "get",
      key: "psaBannerTemplate",
      value: function psaBannerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'psaBanners' */
            "./widgets/psa-banners.js"
          ));
        }, () => {
          return html`
          <psa-banners
            .psaBannerService=${this.psaBannerService}
            .userService=${this.userService}
            .keyValueStoreManager=${this.keyValueStoreManager}
          >
          </psa-banners>
        `;
        });
      }
    }, {
      kind: "method",
      key: "skipToMainContent",
      value: function skipToMainContent(e) {
        var _a;
        e.preventDefault();
        (_a = this.mainContent) == null ? void 0 : _a.scrollIntoView({
          behavior: "smooth"
        });
        setTimeout(() => {
          var _a2;
          (_a2 = this.mainContent) == null ? void 0 : _a2.focus();
        }, 250);
      }
    }, {
      kind: "get",
      key: "limitedSiteFunctionalityBannerTemplate",
      value: function limitedSiteFunctionalityBannerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'alertBanner' */
            "./widgets/alert-banner.js"
          ));
        }, () => {
          return html`
          <alert-banner .level=${"warn"}>
            ${msg("Your browser may not be compatible with all the features on this site. Consider upgrading to a modern browser for an improved experience.")}
          </alert-banner>
        `;
        });
      }
    }, {
      kind: "get",
      key: "zendeskHelpWidgetTemplate",
      value: function zendeskHelpWidgetTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'zenDeskHelpWidget' */
            "../__snowpack__/pkg/@internetarchive/ia-zendesk-help-widget.js"
          ));
        }, () => {
          return html`
          <ia-zendesk-help-widget
            widgetSrc="https://static.zdassets.com/ekr/snippet.js?key=${this.zendeskWidgetKey}"
          ></ia-zendesk-help-widget>
        `;
        });
      }
    }, {
      kind: "get",
      key: "devMenuTemplate",
      value: function devMenuTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'devTools' */
            "./dev-tools.js"
          ));
        }, () => {
          return html`
          <dev-tools
            .appServices=${this.appServices}
            @pageWidthSliderChanged=${this.devToolPageWidthSliderChanged}
            @borderCheckboxChecked=${this.devToolsBorderCheckboxChecked}
          >
          </dev-tools>
        `;
        });
      }
    }, {
      kind: "get",
      key: "activityIndicatorTemplate",
      value: function activityIndicatorTemplate() {
        return html`
      <horizontal-activity-indicator
        .resizeObserver=${this.sharedResizeObserver}
      >
      </horizontal-activity-indicator>
    `;
      }
    }, {
      kind: "get",
      key: "mainTemplate",
      value: function mainTemplate() {
        return html`
      <main id="maincontent" tabindex="-1">
        <div
          class="page-container ${this.showDebugBorders ? "show-debug-borders" : ""}"
        >
          <router-slot></router-slot>
        </div>
      </main>

      <footer class="${this.footerPosition}">${this.footerTemplate}</footer>
    `;
      }
    }, {
      kind: "get",
      key: "modalManagerTemplate",
      value: function modalManagerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'modalManager' */
            "../__snowpack__/pkg/@internetarchive/modal-manager.js"
          ));
        }, () => {
          return html`
          <modal-manager>
            <slot
              name="paypal-upsell-button"
              slot="paypal-upsell-button"
            ></slot>
          </modal-manager>
        `;
        });
      }
    }, {
      kind: "method",
      key: "devToolPageWidthSliderChanged",
      value: function devToolPageWidthSliderChanged(e) {
        var _a, _b;
        const value = e.detail.width;
        (_b = (_a = this.shadowRoot) == null ? void 0 : _a.host) == null ? void 0 : _b.style.setProperty("--app-root-page-max-width", value + "rem");
      }
    }, {
      kind: "method",
      key: "devToolsBorderCheckboxChecked",
      value: function devToolsBorderCheckboxChecked(e) {
        const value = e.detail.showBorders;
        this.showDebugBorders = value;
      }
    }, {
      kind: "method",
      key: "trackTopNavEvent",
      value: async function trackTopNavEvent(e) {
        var _a, _b;
        const [category, action] = (_b = (_a = e.detail) == null ? void 0 : _a.event) == null ? void 0 : _b.split("|");
        if (!category || !action)
          return;
        await this.trackEvent({
          event: {
            category,
            action
          },
          sampling: true
        });
      }
    }, {
      kind: "method",
      key: "trackEvent",
      value: async function trackEvent(options) {
        const appServices = await this.appServices.get();
        const analyticsHandler = await appServices.analyticsHandler.get();
        if (options.sampling) {
          analyticsHandler.sendEvent(options.event);
        } else {
          analyticsHandler.sendEventNoSampling(options.event);
        }
      }
    }, {
      kind: "get",
      key: "footerTemplate",
      value: function footerTemplate() {
        return lazyLoadTemplate(async () => {
          await importRetry(() => import(
            /* webpackChunkName: 'appFooter' */
            "./core/app-footer.js"
          ));
        }, () => {
          return html` <app-footer></app-footer> `;
        });
      }
    }, {
      kind: "get",
      static: true,
      key: "styles",
      value: function styles() {
        return css`
      #skip-to-main-content-link {
        /*http://webaim.org/techniques/css/invisiblecontent/*/
        position: absolute;
        clip: rect(0, 0, 0, 0);
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
        font-size: 1.4rem;
        color: var(--ia-theme-link-color, #4b64ff);
      }
      #skip-to-main-content-link:focus {
        position: static;
        width: auto;
        height: auto;
        outline: thin dotted #333;
        outline: 3px auto -webkit-focus-ring-color;
        margin: 5px 0 2px 8px;
        padding: 2px 3px;
        display: inline-block;
      }
      #skip-to-main-content-link:visited {
        color: var(--ia-theme-link-color, #4b64ff);
      }

      horizontal-activity-indicator {
        position: absolute;
        height: 3px;
        width: 100%;
      }
      modal-manager[mode='closed'] {
        display: none;
      }
      modal-manager.more-search-facets {
        --modalWidth: 85rem;
        --modalBorder: 2px solid #194880;
        --modalTitleLineHeight: 4rem;
        --modalTitleFontSize: 1.8rem;
        --modalCornerRadius: 0;
        --modalBottomPadding: 0;
        --modalScrollOffset: 0;
        --modalCornerRadius: 0.5rem;
      }
      modal-manager.expanded-date-picker {
        --modalWidth: 58rem;
        --modalBorder: 2px solid var(--primaryButtonBGColor, #194880);
        --modalTitleLineHeight: 4rem;
        --modalTitleFontSize: 1.8rem;
        --modalCornerRadius: 0;
        --modalBottomPadding: 0;
        --modalBottomMargin: 0;
        --modalScrollOffset: 0;
        --modalCornerRadius: 0.5rem;
      }
      modal-manager.remove-items {
        --modalWidth: 58rem;
        --modalBorder: 2px solid var(--primaryButtonBGColor, #194880);
        --modalTitleLineHeight: 4rem;
        --modalTitleFontSize: 1.8rem;
        --modalCornerRadius: 0;
        --modalBottomPadding: 0;
        --modalBottomMargin: 0;
        --modalScrollOffset: 0;
        --modalCornerRadius: 0.5rem;
      }
      modal-manager.create-user-list {
        --modalTitleLineHeight: 4.5rem;
        --modalHeaderBottomPadding: 0;
        --modalWidth: 40rem;
      }
      modal-manager.delete-user-list {
        --modalTitleLineHeight: 4.5rem;
        --modalHeaderBottomPadding: 0;
        --modalWidth: 40rem;
      }

      app-footer {
        display: block;
      }
      .page-container {
        margin: auto;
      }
      .page-container.show-debug-borders {
        outline: 1px solid purple;
      }
      footer {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }
      footer.fixed {
        position: fixed;
      }
      footer.off {
        display: none;
      }
    `;
      }
    }]
  };
}, LitElement);
