import {L as LOCALE_STATUS_EVENT} from "../common/lit-localize-0cc05aab.js";
export {c as configureLocalization, m as msg, s as str} from "../common/lit-localize-0cc05aab.js";
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
class LocalizeController {
  constructor(host) {
    this.__litLocalizeEventHandler = (event) => {
      if (event.detail.status === "ready") {
        this.host.requestUpdate();
      }
    };
    this.host = host;
  }
  hostConnected() {
    window.addEventListener(LOCALE_STATUS_EVENT, this.__litLocalizeEventHandler);
  }
  hostDisconnected() {
    window.removeEventListener(LOCALE_STATUS_EVENT, this.__litLocalizeEventHandler);
  }
}
const _updateWhenLocaleChanges = (host) => host.addController(new LocalizeController(host));
const updateWhenLocaleChanges = _updateWhenLocaleChanges;
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const _localized = () => (classOrDescriptor) => typeof classOrDescriptor === "function" ? legacyLocalized(classOrDescriptor) : standardLocalized(classOrDescriptor);
const localized = _localized;
const standardLocalized = ({kind, elements}) => {
  return {
    kind,
    elements,
    finisher(clazz) {
      clazz.addInitializer(updateWhenLocaleChanges);
    }
  };
};
const legacyLocalized = (clazz) => {
  clazz.addInitializer(updateWhenLocaleChanges);
  return clazz;
};
export {localized};
