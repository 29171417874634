function _decorate(decorators, factory, superClass, mixins) {
  var api = _getDecoratorsApi();
  if (mixins) {
    for (var i = 0; i < mixins.length; i++) {
      api = mixins[i](api);
    }
  }
  var r = factory(function initialize(O) {
    api.initializeInstanceElements(O, decorated.elements);
  }, superClass);
  var decorated = api.decorateClass(_coalesceClassElements(r.d.map(_createElementDescriptor)), decorators);
  api.initializeClassElements(r.F, decorated.elements);
  return api.runClassFinishers(r.F, decorated.finishers);
}
function _getDecoratorsApi() {
  _getDecoratorsApi = function() {
    return api;
  };
  var api = {elementsDefinitionOrder: [["method"], ["field"]], initializeInstanceElements: function(O, elements) {
    ["method", "field"].forEach(function(kind) {
      elements.forEach(function(element) {
        if (element.kind === kind && element.placement === "own") {
          this.defineClassElement(O, element);
        }
      }, this);
    }, this);
  }, initializeClassElements: function(F, elements) {
    var proto = F.prototype;
    ["method", "field"].forEach(function(kind) {
      elements.forEach(function(element) {
        var placement = element.placement;
        if (element.kind === kind && (placement === "static" || placement === "prototype")) {
          var receiver = placement === "static" ? F : proto;
          this.defineClassElement(receiver, element);
        }
      }, this);
    }, this);
  }, defineClassElement: function(receiver, element) {
    var descriptor = element.descriptor;
    if (element.kind === "field") {
      var initializer = element.initializer;
      descriptor = {enumerable: descriptor.enumerable, writable: descriptor.writable, configurable: descriptor.configurable, value: initializer === void 0 ? void 0 : initializer.call(receiver)};
    }
    Object.defineProperty(receiver, element.key, descriptor);
  }, decorateClass: function(elements, decorators) {
    var newElements = [];
    var finishers = [];
    var placements = {static: [], prototype: [], own: []};
    elements.forEach(function(element) {
      this.addElementPlacement(element, placements);
    }, this);
    elements.forEach(function(element) {
      if (!_hasDecorators(element))
        return newElements.push(element);
      var elementFinishersExtras = this.decorateElement(element, placements);
      newElements.push(elementFinishersExtras.element);
      newElements.push.apply(newElements, elementFinishersExtras.extras);
      finishers.push.apply(finishers, elementFinishersExtras.finishers);
    }, this);
    if (!decorators) {
      return {elements: newElements, finishers};
    }
    var result = this.decorateConstructor(newElements, decorators);
    finishers.push.apply(finishers, result.finishers);
    result.finishers = finishers;
    return result;
  }, addElementPlacement: function(element, placements, silent) {
    var keys = placements[element.placement];
    if (!silent && keys.indexOf(element.key) !== -1) {
      throw new TypeError("Duplicated element (" + element.key + ")");
    }
    keys.push(element.key);
  }, decorateElement: function(element, placements) {
    var extras = [];
    var finishers = [];
    for (var decorators = element.decorators, i = decorators.length - 1; i >= 0; i--) {
      var keys = placements[element.placement];
      keys.splice(keys.indexOf(element.key), 1);
      var elementObject = this.fromElementDescriptor(element);
      var elementFinisherExtras = this.toElementFinisherExtras((0, decorators[i])(elementObject) || elementObject);
      element = elementFinisherExtras.element;
      this.addElementPlacement(element, placements);
      if (elementFinisherExtras.finisher) {
        finishers.push(elementFinisherExtras.finisher);
      }
      var newExtras = elementFinisherExtras.extras;
      if (newExtras) {
        for (var j = 0; j < newExtras.length; j++) {
          this.addElementPlacement(newExtras[j], placements);
        }
        extras.push.apply(extras, newExtras);
      }
    }
    return {element, finishers, extras};
  }, decorateConstructor: function(elements, decorators) {
    var finishers = [];
    for (var i = decorators.length - 1; i >= 0; i--) {
      var obj = this.fromClassDescriptor(elements);
      var elementsAndFinisher = this.toClassDescriptor((0, decorators[i])(obj) || obj);
      if (elementsAndFinisher.finisher !== void 0) {
        finishers.push(elementsAndFinisher.finisher);
      }
      if (elementsAndFinisher.elements !== void 0) {
        elements = elementsAndFinisher.elements;
        for (var j = 0; j < elements.length - 1; j++) {
          for (var k = j + 1; k < elements.length; k++) {
            if (elements[j].key === elements[k].key && elements[j].placement === elements[k].placement) {
              throw new TypeError("Duplicated element (" + elements[j].key + ")");
            }
          }
        }
      }
    }
    return {elements, finishers};
  }, fromElementDescriptor: function(element) {
    var obj = {kind: element.kind, key: element.key, placement: element.placement, descriptor: element.descriptor};
    var desc = {value: "Descriptor", configurable: true};
    Object.defineProperty(obj, Symbol.toStringTag, desc);
    if (element.kind === "field")
      obj.initializer = element.initializer;
    return obj;
  }, toElementDescriptors: function(elementObjects) {
    if (elementObjects === void 0)
      return;
    return _toArray(elementObjects).map(function(elementObject) {
      var element = this.toElementDescriptor(elementObject);
      this.disallowProperty(elementObject, "finisher", "An element descriptor");
      this.disallowProperty(elementObject, "extras", "An element descriptor");
      return element;
    }, this);
  }, toElementDescriptor: function(elementObject) {
    var kind = String(elementObject.kind);
    if (kind !== "method" && kind !== "field") {
      throw new TypeError(`An element descriptor's .kind property must be either "method" or "field", but a decorator created an element descriptor with .kind "` + kind + '"');
    }
    var key = _toPropertyKey(elementObject.key);
    var placement = String(elementObject.placement);
    if (placement !== "static" && placement !== "prototype" && placement !== "own") {
      throw new TypeError(`An element descriptor's .placement property must be one of "static", "prototype" or "own", but a decorator created an element descriptor with .placement "` + placement + '"');
    }
    var descriptor = elementObject.descriptor;
    this.disallowProperty(elementObject, "elements", "An element descriptor");
    var element = {kind, key, placement, descriptor: Object.assign({}, descriptor)};
    if (kind !== "field") {
      this.disallowProperty(elementObject, "initializer", "A method descriptor");
    } else {
      this.disallowProperty(descriptor, "get", "The property descriptor of a field descriptor");
      this.disallowProperty(descriptor, "set", "The property descriptor of a field descriptor");
      this.disallowProperty(descriptor, "value", "The property descriptor of a field descriptor");
      element.initializer = elementObject.initializer;
    }
    return element;
  }, toElementFinisherExtras: function(elementObject) {
    var element = this.toElementDescriptor(elementObject);
    var finisher = _optionalCallableProperty(elementObject, "finisher");
    var extras = this.toElementDescriptors(elementObject.extras);
    return {element, finisher, extras};
  }, fromClassDescriptor: function(elements) {
    var obj = {kind: "class", elements: elements.map(this.fromElementDescriptor, this)};
    var desc = {value: "Descriptor", configurable: true};
    Object.defineProperty(obj, Symbol.toStringTag, desc);
    return obj;
  }, toClassDescriptor: function(obj) {
    var kind = String(obj.kind);
    if (kind !== "class") {
      throw new TypeError(`A class descriptor's .kind property must be "class", but a decorator created a class descriptor with .kind "` + kind + '"');
    }
    this.disallowProperty(obj, "key", "A class descriptor");
    this.disallowProperty(obj, "placement", "A class descriptor");
    this.disallowProperty(obj, "descriptor", "A class descriptor");
    this.disallowProperty(obj, "initializer", "A class descriptor");
    this.disallowProperty(obj, "extras", "A class descriptor");
    var finisher = _optionalCallableProperty(obj, "finisher");
    var elements = this.toElementDescriptors(obj.elements);
    return {elements, finisher};
  }, runClassFinishers: function(constructor, finishers) {
    for (var i = 0; i < finishers.length; i++) {
      var newConstructor = (0, finishers[i])(constructor);
      if (newConstructor !== void 0) {
        if (typeof newConstructor !== "function") {
          throw new TypeError("Finishers must return a constructor.");
        }
        constructor = newConstructor;
      }
    }
    return constructor;
  }, disallowProperty: function(obj, name, objectType) {
    if (obj[name] !== void 0) {
      throw new TypeError(objectType + " can't have a ." + name + " property.");
    }
  }};
  return api;
}
function _createElementDescriptor(def) {
  var key = _toPropertyKey(def.key);
  var descriptor;
  if (def.kind === "method") {
    descriptor = {value: def.value, writable: true, configurable: true, enumerable: false};
  } else if (def.kind === "get") {
    descriptor = {get: def.value, configurable: true, enumerable: false};
  } else if (def.kind === "set") {
    descriptor = {set: def.value, configurable: true, enumerable: false};
  } else if (def.kind === "field") {
    descriptor = {configurable: true, writable: true, enumerable: true};
  }
  var element = {kind: def.kind === "field" ? "field" : "method", key, placement: def.static ? "static" : def.kind === "field" ? "own" : "prototype", descriptor};
  if (def.decorators)
    element.decorators = def.decorators;
  if (def.kind === "field")
    element.initializer = def.value;
  return element;
}
function _coalesceGetterSetter(element, other) {
  if (element.descriptor.get !== void 0) {
    other.descriptor.get = element.descriptor.get;
  } else {
    other.descriptor.set = element.descriptor.set;
  }
}
function _coalesceClassElements(elements) {
  var newElements = [];
  var isSameElement = function(other2) {
    return other2.kind === "method" && other2.key === element.key && other2.placement === element.placement;
  };
  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    var other;
    if (element.kind === "method" && (other = newElements.find(isSameElement))) {
      if (_isDataDescriptor(element.descriptor) || _isDataDescriptor(other.descriptor)) {
        if (_hasDecorators(element) || _hasDecorators(other)) {
          throw new ReferenceError("Duplicated methods (" + element.key + ") can't be decorated.");
        }
        other.descriptor = element.descriptor;
      } else {
        if (_hasDecorators(element)) {
          if (_hasDecorators(other)) {
            throw new ReferenceError("Decorators can't be placed on different accessors with for the same property (" + element.key + ").");
          }
          other.decorators = element.decorators;
        }
        _coalesceGetterSetter(element, other);
      }
    } else {
      newElements.push(element);
    }
  }
  return newElements;
}
function _hasDecorators(element) {
  return element.decorators && element.decorators.length;
}
function _isDataDescriptor(desc) {
  return desc !== void 0 && !(desc.value === void 0 && desc.writable === void 0);
}
function _optionalCallableProperty(obj, name) {
  var value = obj[name];
  if (value !== void 0 && typeof value !== "function") {
    throw new TypeError("Expected '" + name + "' to be a function");
  }
  return value;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null)
    return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object")
      return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function _toArray(arr) {
  return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter))
    return Array.from(iter);
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr))
    return arr;
}
import {LitElement, html, css} from "../../../__snowpack__/pkg/lit.js";
import {property, query, customElement} from "../../../__snowpack__/pkg/lit/decorators.js";
export let HorizontalActivityIndicator = _decorate([customElement("horizontal-activity-indicator")], function(_initialize, _LitElement) {
  class HorizontalActivityIndicator2 extends _LitElement {
    constructor(...args) {
      super(...args);
      _initialize(this);
    }
  }
  return {
    F: HorizontalActivityIndicator2,
    d: [{
      kind: "field",
      decorators: [property({
        type: Object
      })],
      key: "resizeObserver",
      value: void 0
    }, {
      kind: "field",
      decorators: [property({
        type: Boolean
      })],
      key: "adjustSpeedForWidth",
      value() {
        return true;
      }
    }, {
      kind: "field",
      decorators: [query(".track")],
      key: "track",
      value: void 0
    }, {
      kind: "method",
      key: "render",
      value: function render() {
        return html`
      <div class="track">
        <div class="bar"></div>
      </div>
    `;
      }
    }, {
      kind: "method",
      key: "updated",
      value: function updated(changed) {
        const oldObserver = changed.get("resizeObserver");
        if (oldObserver) {
          oldObserver.removeObserver({
            handler: this,
            target: this.track
          });
        }
        if (changed.has("adjustSpeedForWidth") || changed.has("resizeObserver")) {
          if (this.adjustSpeedForWidth) {
            this.setupResizeObserver();
          } else {
            this.disconnectResizeObserver();
          }
        }
      }
    }, {
      kind: "method",
      key: "handleResize",
      value: function handleResize(entry) {
        const width = entry.contentRect.width;
        switch (entry.target) {
          case this.track:
            this.updateLoaderSpeed(width);
          default:
            break;
        }
      }
    }, {
      kind: "method",
      key: "disconnectedCallback",
      value: function disconnectedCallback() {
        this.disconnectResizeObserver();
      }
    }, {
      kind: "method",
      key: "updateLoaderSpeed",
      value: function updateLoaderSpeed(width) {
        var _a, _b;
        const seconds = Math.max(width / 200, 3);
        (_b = (_a = this.shadowRoot) == null ? void 0 : _a.host) == null ? void 0 : _b.style.setProperty("--horizontalActivityIndicatorDuration", seconds + "s");
      }
    }, {
      kind: "method",
      key: "disconnectResizeObserver",
      value: function disconnectResizeObserver() {
        var _a;
        (_a = this.resizeObserver) == null ? void 0 : _a.removeObserver({
          handler: this,
          target: this.track
        });
      }
    }, {
      kind: "method",
      key: "setupResizeObserver",
      value: async function setupResizeObserver() {
        var _a;
        (_a = this.resizeObserver) == null ? void 0 : _a.addObserver({
          handler: this,
          target: this.track
        });
      }
    }, {
      kind: "get",
      static: true,
      key: "styles",
      value: function styles() {
        const indicatorColor = css`var(--horizontalActivityIndicatorColor, dodgerblue)`;
        const indicatorDuration = css`var(--horizontalActivityIndicatorDuration, 7.5s)`;
        return css`
      :host {
        display: block;
      }

      @keyframes loader-animation {
        0% {
          left: -100%;
          width: 100%;
        }
        49% {
          left: 100%;
          width: 10%;
        }
        50% {
          left: 100%;
          width: 100%;
        }
        100% {
          left: -10%;
          width: 10%;
        }
      }

      .track {
        height: 100%;
        width: 100%;
        overflow: hidden;
      }

      .track .bar {
        position: relative;
        height: 100%;
        background-color: ${indicatorColor};
        animation-name: loader-animation;
        animation-duration: ${indicatorDuration};
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    `;
      }
    }]
  };
}, LitElement);
