function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {value, enumerable: true, configurable: true, writable: true});
  } else {
    obj[key] = value;
  }
  return obj;
}
import {importRetry} from "../../utils/import-retry.js";
import {resolveOwnUserProfile} from "../../scenes/user-profile/resolve-own-user-profile.js";
export class RouteBuilder {
  get appRoutes() {
    return [
      {
        path: "donate",
        pathMatch: "full",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'donatePage' */
          "../../scenes/donate/donate-page.js"
        )),
        setup: (component, info) => {
          const donatePage = component;
          const donationFormSlot = document.createElement("slot");
          donationFormSlot.name = "donation-form-slot";
          donationFormSlot.slot = "donation-form-slot";
          setTimeout(() => {
            donatePage.appendChild(donationFormSlot);
            donatePage.appServices = this.appServices;
          }, 0);
        }
      },
      {
        path: "/details/:id",
        component: () => import("../../scenes/details-page-router.js"),
        setup: (component, info) => {
          const identifier = decodeURIComponent(info.match.params.id);
          const detailsPageRouter = component;
          detailsPageRouter.appServices = this.appServices;
          detailsPageRouter.identifier = identifier;
        }
      },
      {
        path: "/collection/:id",
        resolve: (info) => {
          const identifier = info.match.params.id;
          history.replaceState(history.state, "", `/details/${identifier}${window.location.search}`);
          return false;
        }
      },
      {
        path: "/account/:tab",
        resolve: this.accountRouteResolver
      },
      {
        path: "/account",
        resolve: this.accountRouteResolver
      },
      {
        path: "/search",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'searchPage' */
          "../../scenes/search-page/search-page.js"
        )),
        setup: (component, info) => {
          const searchPage = component;
          searchPage.appServices = this.appServices;
        }
      },
      {
        path: "/$",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'homePage' */
          "../../scenes/home/home-page.js"
        )),
        setup: (component, info) => {
          const homePage = component;
          homePage.appServices = this.appServices;
        }
      }
    ];
  }
  constructor(appServices) {
    _defineProperty(this, "appServices", void 0);
    _defineProperty(this, "accountRouteResolver", (info) => {
      resolveOwnUserProfile(this.appServices, info.match.params.tab);
      return false;
    });
    this.appServices = appServices;
  }
}
